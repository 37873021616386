import { Controller } from "@hotwired/stimulus"

const KEY = "cookies_accepted"

export default class extends Controller {
    connect() {
        if (!this.cookiesAccepted) {
            this.element.hidden = false
        }
    }

    agree() {
        this.cookiesAccepted = true
        this.element.hidden = true
    }

    set cookiesAccepted(value) {
        window.localStorage.setItem(KEY, JSON.stringify(value))
    }

    get cookiesAccepted() {
        return JSON.parse(window.localStorage.getItem(KEY))
    }
}
