import { Controller } from "@hotwired/stimulus"
import nativeToast from "native-toast"

export default class extends Controller {
    connect() {
        nativeToast({
            message: this.element.innerHTML,
            position: "south-east",
            timeout: 5000,
            type: "success",
        })
    }
}
