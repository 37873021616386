import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["backdrop"]

    connect() {
        window.addEventListener("scroll", this.update)
    }

    disconnect() {
        window.removeEventListener("scroll", this.update)
    }

    update = () => {
        const opacity = window.scrollY / window.innerHeight + 0.2
        this.backdropTarget.style.opacity = opacity
    }
}
