import { Controller } from "@hotwired/stimulus"
import { throttle } from "throttle-debounce"

export default class extends Controller {
    connect() {
        this.update()
        window.addEventListener("scroll", this.update)
    }

    disconnect() {
        window.removeEventListener("scroll", this.update)
    }

    update = throttle(300, () => {
        if (window.scrollY > window.innerHeight) {
            this.element.classList.toggle("-expand", true)
        } else {
            this.element.classList.toggle("-expand", false)
        }
    })
}
