import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "increment", "decrement"]

    increment() {
        this.value++
        this.update()
    }

    decrement() {
        this.value--
        this.update()
    }

    update = () => {
        this.decrementTarget.disabled = this.value <= 0
    }

    get value() {
        return parseInt(this.inputTarget.value)
    }

    set value(value) {
        this.inputTarget.value = value
    }
}
