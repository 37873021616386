import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    static targets = ["list", "item", "submitButton"]

    connect() {
        this.sortable = Sortable.create(this.listTarget, {
            animation: 200,
            onEnd: this.change,
        })
    }

    change = () => {
        this.submitButtonTarget.disabled = false
    }
}
