import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from "@hotwired/turbo"
import $ from "jquery"
import lodash from "lodash"
import axios from "axios"
import moment from "moment"
import SmoothScroll from "smooth-scroll"

// -----------------------------------------------------------------------------
// Initialize global libraries
// -----------------------------------------------------------------------------

import "bootstrap"
import "paroller.js"

window._ = lodash
window.$ = window.jQuery = $

moment.locale(document.documentElement.lang)

// -----------------------------------------------------------------------------
// Stimulus & Turbo
// -----------------------------------------------------------------------------

/**
 * We'll load Stimulus & Turbo. Turbo adds SPA-like page navigation
 * to our website and Stimulus attaches JavaScript behavior to HTML.
 */

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Turbo.start()

// -----------------------------------------------------------------------------
// Axios
// -----------------------------------------------------------------------------

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.baseURL = document.body.dataset.url

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    )
}

// -----------------------------------------------------------------------------
// On page loaded
// -----------------------------------------------------------------------------

document.addEventListener("turbo:load", () => {
    // Smooth scroll
    new SmoothScroll('a[href*="#"][data-scroll]', {
        offset: () => {
            if (document.querySelector(".scroll-offset")) {
                return (
                    document.querySelector(".scroll-offset").clientHeight + 10
                )
            }
            return 0
        },
    })

    // Create aliases for Bootstrap's `data-target` attributes
    // because it collides with Stimulus API.

    $("[data-collapse]").on("click", function () {
        $(this.dataset.collapse).collapse("toggle")
        this.classList.toggle("collapsed")
    })

    $("[data-modal]").on("click", function () {
        $(this.dataset.modal).modal("toggle")
    })

    // Parallax effect
    $("[data-paroller-factor]").paroller()

    // Open modals that should be opened by default
    $("[data-modal-show]").each(function () {
        $(this).modal("show")
    })
})
