import { Controller } from "@hotwired/stimulus"
import moment from "moment"
import axios from "axios"
import $ from "jquery"
import "clndr"
import { sum } from "../lib/array"

export default class extends Controller {
    static targets = [
        "calendar",
        "timeOptionList",
        "timeOptionTemplate",
        "dateSectionValue",
        "dateSectionBody",
        "timeSectionHeader",
        "timeSectionValue",
        "timeSectionBody",
        "peopleSectionHeader",
        "peopleSectionValue",
        "peopleSectionBody",
        "peopleCountInput",
        "totalSectionHeader",
        "totalSectionValue",
        "placesIndicator",
        "placesIndicatorLabel",
        "placesIndicatorValue",
        "submitButton",
    ]

    calendarTemplate = `
        <div class='clndr-controls'>
            <div class='clndr-control-button'>
                <span class='clndr-previous-button'>
                    <i class="material-icons">
                        keyboard_arrow_left
                    </i>
                </span>
            </div>
            <div class='month'><%= month %> <%= year %></div>
            <div class='clndr-control-button rightalign'>
                <span class='clndr-next-button'>
                    <i class="material-icons">
                        keyboard_arrow_right
                    </i>
                </span>
            </div>
        </div>
        <table class='clndr-table' border='0' cellspacing='0' cellpadding='0'>
            <thead>
                <tr class='header-days'>
                <% for(var i = 0; i < daysOfTheWeek.length; i++) { %>
                    <td class='header-day'><%= daysOfTheWeek[i] %></td>
                <% } %>
                </tr>
            </thead>
            <tbody>
            <% for(var i = 0; i < numberOfRows; i++){ %>
                <tr>
                <% for(var j = 0; j < 7; j++){ %>
                <% var d = j + i * 7; %>
                    <td class='<%= days[d].classes %>'>
                        <div class='day-contents'><%= days[d].day %></div>
                    </td>
                <% } %>
                </tr>
            <% } %>
            </tbody>
        </table>
    `

    connect() {
        this.calendar = $(this.calendarTarget).clndr({
            template: this.calendarTemplate,
            clickEvents: {
                click: this.changeDay,
                onMonthChange: this.changeMonth,
            },
        })

        this.changeMonth()
    }

    get selectedEvent() {
        return JSON.parse(this.data.get("selectedEvent"))
    }

    set selectedEvent(value) {
        this.data.set("selectedEvent", JSON.stringify(event))
    }

    changeMonth = async (month = moment()) => {
        const { data } = await axios.get(
            `/api/activity/${this.element.dataset.activityId}/events`,
            {
                params: {
                    date: month.format(),
                    range: "month",
                },
            }
        )

        this.events = data.map((event) => ({
            ...event,
            date: event.starts_at,
        }))

        this.calendar.addEvents(this.events)
    }

    changeDay = async ({ date, events, element }) => {
        if (!element.classList.contains("event")) return

        this.calendarTarget
            .querySelectorAll(".day")
            .forEach(($el) => $el.classList.remove("selected"))
        element.classList.add("selected")

        this.timeOptionListTarget.innerHTML = ""

        events.forEach((event) => {
            const timeOption = document.importNode(
                this.timeOptionTemplateTarget.content,
                true
            )
            const date = moment(event.starts_at)

            const input = timeOption.querySelector(".time-option__input")

            input.value = event.id
            input.dataset["event"] = JSON.stringify(event)

            timeOption.querySelector(".time-option__time").textContent =
                date.format("H:mm")
            timeOption.querySelector(".time-option__places").textContent = `${
                event.available_places
            } ${event.available_places > 1 ? "plazas" : "plaza"}`

            this.timeOptionListTarget.appendChild(timeOption)
        })

        this.dateSectionValueTarget.textContent = date.format("dddd, L")
        $(this.dateSectionBodyTarget).collapse("hide")

        this.timeSectionHeaderTarget.disabled = false
        $(this.timeSectionBodyTarget).collapse("show")

        this.peopleSectionHeaderTarget.disabled = true
        $(this.peopleSectionBodyTarget).collapse("hide")

        this.totalSectionHeaderTarget.classList.add("-disabled")
        $(this.totaleSectionBodyTarget).innerHTML = ""
    }

    changeTime = ({ target }) => {
        this.data.set("selectedEvent", target.dataset.event)

        this.timeSectionValueTarget.textContent = moment(
            this.selectedEvent.starts_at
        ).format("H:mm")
        $(this.timeSectionBodyTarget).collapse("hide")

        this.peopleSectionHeaderTarget.disabled = false
        $(this.peopleSectionBodyTarget).collapse("show")

        this.placesIndicatorValueTarget.textContent =
            this.selectedEvent.available_places
    }

    changeTariffOne = ({ currentTarget }) => {
        const tariff = JSON.parse(currentTarget.dataset.tariff)

        this.calculateResult(tariff.price, tariff.people)
    }

    changeTariffMany = () => {
        const totalPrice = sum(
            this.peopleCountInputTargets.map(
                (element) =>
                    parseInt(JSON.parse(element.dataset.tariff).price) *
                    parseInt(element.value || 0)
            )
        )

        const totalPeople = sum(
            this.peopleCountInputTargets.map((element) =>
                parseInt(element.value || 0)
            )
        )

        this.calculateResult(totalPrice, totalPeople)
    }

    calculateResult = (totalPrice, totalPeople) => {
        const availablePlaces =
            this.selectedEvent.available_places - totalPeople

        if (availablePlaces < 0) {
            this.placesIndicatorTarget.classList.add("text-danger")
            this.placesIndicatorValueTarget.textContent = -availablePlaces
            this.placesIndicatorLabelTarget.textContent = "Límite superado: "
            this.submitButtonTarget.disabled = true
        } else {
            this.placesIndicatorTarget.classList.remove("text-danger")
            this.placesIndicatorValueTarget.textContent = availablePlaces
            this.placesIndicatorLabelTarget.textContent = "Plazas disponibles: "
            this.submitButtonTarget.disabled = false
        }

        this.peopleSectionValueTarget.textContent = totalPeople

        this.totalSectionHeaderTarget.classList.remove("-disabled")
        this.totalSectionValueTarget.innerHTML = `${totalPrice}&nbsp;€`

        if (totalPeople <= 0) {
            this.submitButtonTarget.disabled = true
        }
    }

    get activity() {
        return JSON.parse(this.element.dataset.activity)
    }
}
