import { Controller } from "@hotwired/stimulus"
import React from "react"
import ReactDOM from "react-dom"
import Select from "react-select"

export default class extends Controller {
    static targets = ["output"]

    container = null
    elm = null
    groups = {}
    name = ""
    options = {}
    value = {}

    connect() {
        this.groups = JSON.parse(this.data.get("groups"))
        this.name = this.data.get("name") + "[]"
        this.options = JSON.parse(this.data.get("options"))
        this.value = JSON.parse(this.data.get("value"))
        this.container = this.element.querySelector("#multiselect-container")

        this.render()
    }

    changeGroup(e) {
        this.options =
            this.groups[e.target.value] !== undefined
                ? this.groups[e.target.value]
                : {}
        this.value = {}
        this.elm.select.clearValue()
        this.render()
    }

    render() {
        // eslint-disable-next-line
        this.elm = ReactDOM.render(
            <Select
                className="multiselect"
                defaultValue={this.value}
                isMulti={true}
                name={this.name}
                options={this.options}
            />,
            this.container
        )
    }
}
