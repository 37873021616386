import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    openLightbox(event) {
        const lightbox = this.application.controllers.find(
            (controller) => controller.identifier === "lightbox"
        )

        lightbox.openAt(event.params.index)
    }
}
