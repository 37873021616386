export class GeolocationNotSupportedError extends Error {}

export async function getCurrentPosition() {
    if (!navigator.geolocation) {
        throw new GeolocationNotSupportedError("Geolocation not supported")
    }

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            },
            (error) => {
                reject(error)
            }
        )
    })
}
