import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["buttonPrevious", "buttonNext", "image"]
    static classes = ["open"]

    static values = {
        index: Number,
        images: Array,
    }

    connect() {
        window.addEventListener("keydown", this.onKeyDown)
    }

    disconnect() {
        window.removeEventListener("keydown", this.onKeyDown)
    }

    onKeyDown = ({ key }) => {
        if (this.isOpen) {
            if (key === "ArrowLeft" && this.hasPrevious) {
                this.goPrevious()
            }
            if (key === "ArrowRight" && this.hasNext) {
                this.goNext()
            }
            if (key === "Esc" || key === "Escape") {
                this.close()
            }
        }
    }

    get hasPrevious() {
        return this.indexValue > 0
    }

    get hasNext() {
        return this.indexValue < this.imagesValue.length - 1
    }

    get isOpen() {
        return this.hasOpenClass
    }

    goPrevious() {
        this.indexValue--
    }

    goNext() {
        this.indexValue++
    }

    indexValueChanged() {
        this.imageTarget.src = this.imagesValue[this.indexValue].src
        this.buttonPreviousTarget.disabled = !this.hasPrevious
        this.buttonNextTarget.disabled = !this.hasNext
    }

    clickAway(event) {
        if (
            ![
                this.buttonPreviousTarget,
                this.buttonNextTarget,
                this.imageTarget,
            ].some((target) => target.contains(event.target))
        ) {
            this.close()
        }
    }

    open() {
        this.element.classList.add(this.openClass)
    }

    openAt(index) {
        this.open()
        this.indexValue = index
    }

    close() {
        this.element.classList.remove(this.openClass)
    }
}
