import { Controller } from "@hotwired/stimulus"
import { throttle } from "throttle-debounce"

export default class extends Controller {
    connect() {
        this.lastScroll = 0

        window.addEventListener("scroll", this.scroll)
    }

    scroll = throttle(300, () => {
        if (window.scrollY <= this.lastScroll || this.lastScroll <= 0) {
            this.pin()
        } else {
            this.unpin()
        }

        this.lastScroll = window.scrollY
    })

    pin() {
        if (this.element.classList.contains("-unpinned")) {
            this.element.classList.remove("-unpinned")
        }
    }

    unpin() {
        if (!this.element.classList.contains("-unpinned")) {
            this.element.classList.add("-unpinned")
        }
    }
}
