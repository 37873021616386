import { Controller } from "@hotwired/stimulus"
import {
    GeolocationNotSupportedError,
    getCurrentPosition,
} from "../lib/geolocation"

/* global GeolocationPositionError */

const messages = {
    geolocationNotSupported: "El navegador no soporta la geolocalización",
    geolocationPermissionDenied:
        "El sitio web no tiene permiso para detectar la geolocalización",
    geolocationPositionUnavailable: "Geolocalización no disponible",
    geolocationError: "Falla el escaneo de geolocalización",
}

export default class extends Controller {
    static targets = ["map", "locationButton"]

    static values = {
        //: Array<Business>
        businesses: Array,
        //: string
        myLocationIcon: String,
        //: "inactive" | "loading" | "active" | "refreshing"
        myLocationState: { type: String, default: "inactive" },
    }

    map = null
    myLocationMarker = null
    myLocationRefreshInterval = null

    connect() {
        var center = { lat: 50.07896183996463, lng: 14.40705254159905 }

        this.map = new google.maps.Map(this.mapTarget, {
            zoom: 13,
            center,
            fullscreenControl: false,
        })

        this.initializeBusinessMarkers()
        this.initializeBusinessDetail()

        window.addEventListener("turbo:visit", this.initializeBusinessDetail)
    }

    disconnect() {
        window.removeEventListener("turbo:visit", this.initializeBusinessDetail)
    }

    initializeBusinessMarkers = () => {
        this.businessesValue.forEach((business) => {
            if (business.location) {
                const title =
                    business.discount && parseInt(business.discount) !== 0
                        ? `${business.title} (-${business.discount} ${business.discount_type})`
                        : business.title

                const icons = this.createMarkerIcons(
                    business.category.map_icon,
                    business.category.color,
                    business.category.map_icon_color_mask
                )

                const marker = this.addMarker({
                    position: business.location_parsed,
                    title,
                    icon: icons.icon,
                })

                marker.addListener("click", () => {
                    this.visitBusiness(business.id)
                })
            }
        })
    }

    visitBusiness = (id) => {
        const link = document.querySelector(`#business-${id}-link`)

        if (link) {
            link.click()
        }
    }

    initializeBusinessDetail = () => {
        const params = new URLSearchParams(window.location.search)
        const id = params.get("id")

        if (id !== null) {
            const business = this.getBusiness(parseInt(id))

            if (business && business.location_parsed) {
                console.log("Initializing business detail...", {
                    id: parseInt(id),
                    location: business.location_parsed,
                })
                this.map.setZoom(16)
                this.map.panTo(business.location_parsed)
            }
        }
    }

    getBusiness = (id) =>
        this.businessesValue.find((business) => business.id === id)

    addMarker(options) {
        return new google.maps.Marker({
            ...options,
            map: this.map,
        })
    }

    removeMarker(marker) {
        marker.setMap(null)
    }

    createMarkerIcons(
        svgContent,
        color = null,
        colorMask = null,
        width = 32,
        height = 32,
        selectedWidth = 40,
        selectedHeight = 40
    ) {
        let content = svgContent.replace(/\n/g, " ")

        if (color && colorMask) {
            let regexp = new RegExp(colorMask, "g")
            content = content.replace(regexp, color)
        }

        let icon = {
            url: "data:image/svg+xml;charset=UTF-8;base64," + btoa(content),
            size: new google.maps.Size(width, height),
            scaledSize: new google.maps.Size(width, height),
        }
        let iconSelected = {
            url: "data:image/svg+xml;charset=UTF-8;base64," + btoa(content),
            size: new google.maps.Size(selectedWidth, selectedHeight),
            scaledSize: new google.maps.Size(selectedWidth, selectedHeight),
        }

        return {
            icon,
            iconSelected,
        }
    }

    async toggleLocation() {
        if (this.myLocationStateValue === "inactive") {
            this.turnLocationOn()
        } else {
            this.turnLocationOff()
        }
    }

    async turnLocationOn() {
        this.myLocationStateValue =
            this.myLocationStateValue === "active" ? "refreshing" : "loading"

        try {
            const position = await getCurrentPosition()

            this.myLocationMarker = this.addMarker({
                position,
                icon: {
                    url: this.myLocationIconValue,
                    anchor: { x: 16, y: 16 },
                },
            })

            this.map.panTo(position)
            this.map.setZoom(18)

            this.myLocationRefreshInterval = setInterval(async () => {
                this.myLocationStateValue = "refreshing"

                try {
                    const newPosition = await getCurrentPosition()
                    this.myLocationMarker.setPosition(newPosition)
                    this.myLocationStateValue = "active"
                } catch (error) {
                    this.handleLocationError(error)
                    this.turnLocationOff()
                }
            }, 10 * 1000)

            this.myLocationStateValue = "active"
        } catch (error) {
            this.handleLocationError(error)
            this.turnLocationOff()
        }
    }

    turnLocationOff() {
        if (this.myLocationRefreshInterval) {
            clearInterval(this.myLocationRefreshInterval)
        }

        if (this.myLocationMarker) {
            this.removeMarker(this.myLocationMarker)
            this.myLocationMarker = null
        }

        this.myLocationStateValue = "inactive"
    }

    handleLocationError(error) {
        if (error instanceof GeolocationNotSupportedError) {
            return alert(messages.geolocationNotSupported)
        }

        if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
            return alert(messages.geolocationPermissionDenied)
        }

        if (error.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
            return alert(messages.geolocationPositionUnavailable)
        }

        alert(messages.geolocationError)
        console.error("The Geolocation service failed", error)
    }

    myLocationStateValueChanged() {
        // prettier-ignore
        this.locationButtonTarget.classList.toggle("-active",
            this.myLocationStateValue === "active" ||
            this.myLocationStateValue === "refreshing"
        )

        this.locationButtonTarget.disabled =
            this.myLocationStateValue === "loading" ||
            this.myLocationStateValue === "refreshing"

        const iconTarget =
            this.locationButtonTarget.querySelector(".button__icon")

        iconTarget.innerHTML =
            iconTarget.dataset[
                this.myLocationStateValue === "active" ||
                this.myLocationStateValue === "refreshing"
                    ? "contentActive"
                    : "contentInactive"
            ]

        const contentTarget =
            this.locationButtonTarget.querySelector(".button__content")

        contentTarget.innerHTML =
            contentTarget.dataset[
                {
                    inactive: "contentInactive",
                    loading: "contentLoading",
                    active: "contentActive",
                    refreshing: "contentActive",
                }[this.myLocationStateValue]
            ]
    }
}
