import { Controller } from "@hotwired/stimulus"
import { debounce } from "throttle-debounce"
import axios from "axios"

export default class extends Controller {
    static targets = ["input", "success", "error"]

    update = debounce(300, async () => {
        if (this.inputTarget.value) {
            const code = this.inputTarget.value
            const { data } = await axios.get(`/api/coupon`, {
                params: { code },
            })

            this.successTarget.classList.toggle("-active", !!data)
            this.errorTarget.classList.toggle("-active", !data)
        } else {
            this.successTarget.classList.remove("-active")
            this.errorTarget.classList.remove("-active")
        }
    })
}
