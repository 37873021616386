import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static classes = ["collapsed"]

    expandCard() {
        this.element.classList.remove(this.collapsedClass)
    }

    collapseCard() {
        this.element.classList.add(this.collapsedClass)
    }
}
