import { Controller } from "@hotwired/stimulus"
import mapbox from "mapbox-gl"
import MapboxLanguage from "@mapbox/mapbox-gl-language"

export default class extends Controller {
    connect() {
        const [lat, lng] = this.data
            .get("position")
            .split(",")
            .map((s) => s.trim())
            .map(parseFloat)
        const location = { lat, lng }

        mapbox.accessToken = this.data.get("token")

        const map = new mapbox.Map({
            center: location,
            zoom: 15,
            container: this.element,
            style: "mapbox://styles/mapbox/streets-v10",
            scrollZoom: false,
        })

        map.addControl(
            new mapbox.NavigationControl({
                showCompass: false,
            })
        )

        map.addControl(
            new MapboxLanguage({
                defaultLanguage: "es",
            })
        )

        new mapbox.Marker({
            color: "#FB7564",
        })
            .setLngLat(location)
            .addTo(map)
    }
}
