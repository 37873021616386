import { Controller } from "@hotwired/stimulus"
import Hammer from "hammerjs"

export default class extends Controller {
    static targets = ["slide", "container", "dot"]

    initialize() {
        this.show(0)
    }

    connect() {
        this.hammer = new Hammer(this.element)

        // Enable swipe gestures oun touch screens
        this.hammer
            .on("panright", () => this.previous())
            .on("panleft", () => this.next())

        if (this.data.has("interval")) {
            this.interval = setInterval(
                () => this.cycle(),
                this.data.get("interval")
            )
        }
    }

    disconnect() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    previous() {
        if (this.index != 0) {
            this.show(this.index - 1)
        }
    }

    next() {
        if (this.index + 1 != this.count) {
            this.show(this.index + 1)
        }
    }

    cycle() {
        if (this.index + 1 == this.count) {
            this.show(0)
        } else {
            this.next()
        }
    }

    goTo({ target }) {
        this.show(parseInt(target.dataset.slide))
    }

    show(index) {
        this.index = index
        this.containerTarget.style.transform = `translateX(-${index * 100}%)`

        this.dotTargets
            .map((dot) => (dot.classList.remove("-current"), dot))
            .find((dot) => parseInt(dot.dataset.slide) == index)
            .classList.add("-current")
    }

    get count() {
        return this.slideTargets.length
    }
}
